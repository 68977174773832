.header {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 60px;
    display: inline-block;
}

.darkReport {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #000000;
    color: white;
    font-size: 16px;
    float: left;
    position: relative;
    margin-left: 80px;
    margin-top: 20px;
}

.darkReport:hover{
    cursor: pointer;
}

.darkReport1 {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #000000;
    color: white;
    font-size: 16px;
    float: left;
    position: relative;
    margin-left: 80px;
    margin-top: -50px;
}

.darkReport1:hover{
    cursor: pointer;
}

.lightReport {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #FFFFFF;
    color: black;
    font-size: 16px;
    float: left;
    position: relative;
    margin-left: 80px;
    margin-top: -50px;
}

.lightReport:hover{
    cursor: pointer;
}

.headerLabel-fixDark1 {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: left;
    margin: 10px;
    margin-left : 150px;
    color: #01c3d3;
    text-decoration: none;
}

.headerLabel-fix2 {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: left;
    margin: 10px;
    margin-left : 150px;
    color: #015c6f;
    text-decoration: none;
}

.headerLabelUser-fixDark {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin: -48px;
    margin-right : 80px;
    display: block;
    color: #01c3d3;
    text-decoration: none;
}

.headerLabelPass-fixDark {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin-right: 80px;
    margin-top: 48px;
    color: #01c3d3;
    text-decoration: none;
}

.header2 {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 60px;
    display: inline-block;
}

.iframenya {
    margin-top: 17px;
    margin-left: 64px;
}

.iframenya2 {
    margin-top: 17px;
    margin-left: 64px;
}

.headerLabel-fix {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: left;
    margin: 10px;
    margin-left : 100px;
    color: #015c6f;
}

.headerLabelUser {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin: -48px;
    margin-right : 80px;
    display: block;
    color: #015c6f;
}

.headerLabelPass {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin-right: 80px;
    margin-top: 48px;
    color: #015c6f;

}

.userIcon {
    display: block;
    margin-left: auto;
    width:90px;
    height: 90px;
    margin-top: -80px;
}

.titlePage {
    display: block;
    margin-top: 100px;
    font-size: 35px;
    color: #015c6f;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: center;
}

.menuIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:150px;
    height: 150px;
    margin-top: 50px;
}

.newCaseIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 70px;
    margin-bottom: 12px;
}

.draftIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 70px;
}

.inboxIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
}

.reportIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 12px;
}

.background-nya {
    background-image: url('../../images/bgwhite.jpg');
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: inline-block;
}

.bagianMenu {
    text-align: center;
}
.bagianMenu1 {
    text-align: center;
}

.buttonMenu {
    outline: none;
    background: transparent;
    border-color: transparent;
}

.buttonMenu :hover{
    cursor: pointer;
}

.caseList {
    margin: auto;
    width: 1000px;
    padding: 20px;
    border-radius: 10px;
    font-weight: bold;
    background-color: white;
    cursor: pointer;
    border: 3px solid #707070;
}

.form-search {
    margin:auto;
    width: 800px;
}

.form-search input[type="text"] {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
}