.header {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 60px;
    text-align: center;
}

.headerLight {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 60px;
    text-align: center;
}

.headerDark {
    background-color: #1f2757;
    width: 100%;
    position: absolute;
    height: 60px;
    text-align: center;
}

.dark {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #000000;
    color: white;
    font-size: 16px;
    float: right;
    margin-top: -45px;
    margin-right: 20px;
}

.dark:hover{
    cursor: pointer;
}

.light {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #FFFFFF;
    color: black;
    font-size: 16px;
    float: right;
    margin-top: -45px;
    margin-right: 20px;
}

.light:hover{
    cursor: pointer;
}

.background {
    background-image: url('../../images/lightmode.png');
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    display: inline-block;
}

.background-dark {
    background-image: url('../../images/darkmode4.png');
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    display: inline-block;
}

.belakang {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 100%;
    text-align: center;
}

.textHeader {
    text-align: left;
    margin-left: 80px;
    font-size: 30px;
    font-weight: bold;
    color: #015c6f;
    margin-top: 7px;

}

.textHeaderDark {
    text-align: left;
    margin-left: 80px;
    font-size: 30px;
    font-weight: bold;
    color: #01c3d3;
    margin-top: 7px;

}


.user {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:250px;
    height: 250px;
    margin-top: 50px;
}

.user2 {
    width:30px;
    height: 30px;
}

.logo {
    display: block;
    margin-right: auto;
    margin-left: 20px;
    margin-top: -73px;
    width:50px;
    height: 50px;

}

.labelUser {
    width: 20%;
    padding: 12px 20px;
    margin: auto;
    margin-bottom: 20px;
    border: 2px solid #015c6f;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
}

.labelPass {
    width: 20%;
    padding: 12px 20px;
    margin: auto;
    margin-bottom: 20px;
    border: 2px solid #015c6f;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
}

.mata {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:30;
    height: 30px;
}

.password {
    display: inline-block;
}
.bagianPass {
    text-align: center;
    display: block;
}

.btn {
    padding: 10px 36px;
    border: 1px solid #111111;
    border-radius: 20px;
    background: #015c6f;
    color: white;
    font-size: 16px;
    text-align: center;
}

.btn:hover {
    cursor: pointer;
}

.login {
    text-align: center;
}