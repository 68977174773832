.tombolAssign {
    float: right;
    margin-top: -180px;
    font-size: 18px;
    margin-right: 15px;
    width: 145px;
    text-align: left;
}

.tombolAssign:hover {
    cursor: pointer;
}

.judulReassign{
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 490px;
}

.kotakpesan {
    text-align: center;
}

.kotaknya{
    width: 467px;
    height: 100px;
    margin-left: -16px;
}

.tabelnya {
    margin-top: 20px;
}

.tombolConfirm {
    margin: auto;
}