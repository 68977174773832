.header {
    background-color: #00ffc6;
    width: 100%;
    position: absolute;
    height: 60px;
    display: inline-block;
}

.headerDarkDraft {
    background-color: #1f2757;
    width: 100%;
    position: absolute;
    height: 60px;
    text-align: center;
}

.darkDraft {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #000000;
    color: white;
    font-size: 16px;
    float: left;
    margin-left: 80px;
}

.darkDraft:hover{
    cursor: pointer;
}

.lightDraft {
    padding: 3px 7px;
    border: 1px solid #111111;
    border-radius: 30px;
    background: #FFFFFF;
    color: black;
    font-size: 16px;
    float: left;
    margin-left: 80px;
}

.lightDraft:hover{
    cursor: pointer;
}

.headerLabel-fixDark {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: left;
    margin: 10px;
    margin-left : 100px;
    color: #01c3d3;
    text-decoration: none;
}

.headerLabelUser-fixDark {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin: -48px;
    margin-right : 80px;
    display: block;
    color: #01c3d3;
    text-decoration: none;
}

.headerLabelPass-fixDark {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin-right: 80px;
    margin-top: 48px;
    color: #01c3d3;
    text-decoration: none;
}


.headerLabel-fix {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: left;
    margin: 10px;
    margin-left : 100px;
    color: #015c6f;
}

.headerLabelUser {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin: -48px;
    margin-right : 80px;
    display: block;
    color: #015c6f;
}

.headerLabelPass {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: right;
    margin-right: 80px;
    margin-top: 48px;
    color: #015c6f;

}

.iNya {
    display: block;
    margin-left: auto;
    width:90px;
    height: 90px;
    margin-top: -78px;
}

.titlePage {
    display: block;
    margin-top: 100px;
    font-size: 35px;
    color: #015c6f;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: center;
}

.loadingCircle {
    display: block;
    font-size: 20px;
    color: #015c6f;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: center;
}

.menuIcon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:150px;
    height: 150px;
    margin-top: 50px;
}

.newCaseIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 70px;
    margin-bottom: 12px;
}

.draftIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 70px;
}

.iconnya {
    display: block;
    justify-content: right;

}

.tampil{
    flex-direction: column-reverse;
}

.inboxIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
}

.reportIcon {
    display: inline-block;
    width: 150px;
    box-sizing: border-box;
    margin-right: 12px;
}

.background-nya {
    background-image: url('../../images/bgwhite.jpg');
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: inline-block;
}

.bagianMenu {
    text-align: center;
}
.bagianMenu1 {
    text-align: center;
}

.buttonMenu {
    outline: none;
    background: transparent;
    border-color: transparent;
}

.buttonMenu :hover{
    cursor: pointer;
}

.draftListLight {
    margin: auto;
    width: 1000px;
    padding: 25px;
    font-size: 20px;
    border-radius: 10px;
    margin-bottom: 45px;
    color: black;
    background-color: white;
    opacity: 90%;
    cursor: pointer;
    border: 3px solid #707070;
}

.draftListRealDark {
    margin: auto;
    width: 1000px;
    padding: 25px;
    font-size: 20px;
    border-radius: 10px;
    margin-bottom: 45px;
    color: white;
    background-color: gray;
    cursor: pointer;
    border: 3px solid #FFFFFF;
}

.draftList {
    margin: auto;
    margin-bottom: 40px;
    width: 1000px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    border: 3px solid #707070;
    line-height: 2em;
}

.iconBuangY {
    display: block;
    width: 120px;
    height: 50px;
    margin: auto;
    margin-top: -130px;
    margin-right: 15px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 55px;
    border: 4px solid #000000;
    font-size: 18px;
}

.iconBuangY:hover{
    cursor: pointer;
}

.iconBuangYRealDark {
    display: block;
    width: 120px;
    height: 50px;
    margin: auto;
    margin-top: -175px;
    margin-right: 15px;
    color: white;
    background-color: gray;
    border-radius: 20px;
    margin-bottom: 55px;
    border: 4px solid #FFFFFF;
    font-size: 18px;
}

.iconBuangYRealDark:hover{
    cursor: pointer;
}


.iconReassignYRealDark {
    display: block;
    width: 120px;
    height: 50px;
    margin: auto;
    margin-top: -40px;
    margin-right: 15px;
    color: white;
    background-color: gray;
    border-radius: 20px;
    margin-bottom: 55px;
    border: 4px solid #FFFFFF;
    font-size: 18px;
}

.iconReassignYReal {
    display: block;
    width: 120px;
    height: 50px;
    margin: auto;
    margin-top: -40px;
    margin-right: 15px;
    color: black;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 55px;
    border: 4px solid #000000;
    font-size: 18px;
}

.iconBuangYReal {
    display: block;
    width: 120px;
    height: 50px;
    margin: auto;
    margin-top: -175px;
    margin-right: 15px;
    color: black;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 55px;
    border: 4px solid #000000;
    font-size: 18px;
}

.iconReassignYRealDark:hover{
    cursor: pointer;
}